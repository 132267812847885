import React from 'react';
import Navbar from '../Component/Navbar/NavModified';
import Footer from '../Component/Footer/Footer';
import PerspectiveHero from '../Component/Perspective/perspectiveHero';
import Youtube from '../Component/Youtube/Youtube';

const Perspective=()=>{
  return(
   <div>
   <Navbar/>
   <PerspectiveHero/>
   <Youtube/>
   
   <Footer/>

   </div>
  );
}

export default Perspective;