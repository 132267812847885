import React from "react";
import "./Team.css";

const TeamPage = () => {
  return (
    <div className="background-image">
      <div className="hero-Container4">
        <div className="hero-text4">
          <h1>Schedule a Call</h1>
          <p>with our Presentation Experts</p>
          
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
