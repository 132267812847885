export const MenuItems = [
  {
    title: "Partnerships",
    url: "/Partnerships",
    cName: "nav-links"
    
  },
  {
    title: "Propositions",
    url: "/Propositions",
    cName: "nav-links"

  },
  {
    title: "Perspective",
    url: "/Perspective",
    cName: "nav-links"
    
  },

  {
    title: "People",
    url: "/People",
    cName: "nav-links"
    
  }
];
