import React from "react";
import "./Herostyles.css";

const Hero = () => {
  return (
    <div className="background-image">
      <div className="hero-Container">
        <div className="hero-text">
          <h1>Decks, Fund Raise<br /><span className="secondline">and Strategy</span></h1>
          <p>From Pre-Seed to Series B and above.</p>
          <button className="btn" onClick={() => window.location.href = 'https://calendly.com/nilambargiri_unblue/fund-raising-consultation'}>Schedule a Call</button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
