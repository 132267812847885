import React from 'react';
import Navbar from '../Component/Navbar/NavModified';
import Footer from '../Component/Footer/Footer';
import  PartnershipHero from "../Component/PartnershipHero/PartnershipHero";
import Postcontainer from "../Component/PartnershipHero/postcontainer"


const Partnerships=()=>{
  return(
   <div>
   <Navbar/>
   <PartnershipHero/>
   <Postcontainer/>
   <Footer/>

   </div>
  );
}

export default Partnerships;