import React from "react";
import "./perspectiveHero.css";
import linkedin from "../../Assets/followlinkedin.png";
import youtube from "../../Assets/youtube.png";

const perspectiveHero = () => {
  return (
      <div className="hero-Container2">
        <div className="hero-text2">
          <h1>Fundraising Consultants<br/>and Presentation Makers</h1>
          <div className="socialsimage">
          <div className="followlinkedin">
          <a href="https://www.linkedin.com/in/nilambar-giri/">
    <img src={linkedin} alt="linkedinimage" />
</a>
        
        </div>
        <div className="followyoutube">
        <a href="https://www.youtube.com/channel/UCxCLSk75k_3senmzV5frRpQ">
          <img src={youtube} alt="youtubeimage" /></a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default perspectiveHero;
