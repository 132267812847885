import React from "react";
import "./Contentpart.css";
import c1 from "../../Assets/c1.png";
import c2 from "../../Assets/c2.png";
import c3 from "../../Assets/c3.png";
import c4 from "../../Assets/c4.png";
import c5 from "../../Assets/c5.png";
import c6 from "../../Assets/c6.png";
import c7 from "../../Assets/c7.png";
// import c8 from "../../Assets/c8.png";

const PerspectiveHero = () => {
  return (
    <div className="hero-Container__content">
      <div className="hero-text__content">
        <h1>
        Engaging Storytelling
          <br />
        for Business Growth 
        </h1>
        <p>
        We transform your business vision into compelling narratives for pitch decks and presentations, ensuring your message resonates and leaves a lasting impact. Perfect for startups and businesses aiming for growth.
        </p>
      </div>
      <div className="topic-container">
        <div className="part1">
          <ul className="mini-container">
            <li>
              <img className="icon_small" src={c1} width="40" alt="c1" />
              <p>Problem</p>
            </li>
            <li>
              <img className="icon_small" src={c2} width="40" alt="c2" />
              <p>Investors</p>
            </li>
            <li>
              <img className="icon_small" src={c3} width="40" alt="c3" />
              <p>Solution</p>
            </li>
            <li>
              <img className="icon_small4" src={c4} width="40" alt="c4" />
              <p>Market Size TAM/SAM/SOM</p>
            </li>
          </ul>
        </div>
        <div className="part2">
          <ul className="mini-container">
            <li>
              <img className="icon_small" src={c5} width="40" alt="c5" />
              <p>Why Now?</p>
            </li>
            <li>
              <img className="icon_small" src={c6} width="40" alt="c6" />
              <p>Use of Funds</p>
            </li>
            <li>
              <img className="icon_small" src={c7} width="40" alt="c7" />
              <p>Business Model</p>
            </li>
            <li>
              <img className="icon_small" src={c6} width="40" alt="c8" />
              <p>Unit Economics</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PerspectiveHero;
