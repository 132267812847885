import React from 'react';
import Navbar from '../Component/Navbar/NavModified';
import PropositionsHero from '../Component/Proposition/PropositionsHero';
import Footer from '../Component/Footer/Footer';
import MeetingScript from '../Component/Proposition/MeetingScript';
import Contentpart from '../Component/Proposition/Contentpart';
const Propositions=()=>{
  return(
   <div>
   <Navbar/>
   <PropositionsHero/>
   <Contentpart/>
   <MeetingScript/>
   <Footer/>

   </div>
  );
}

export default Propositions;