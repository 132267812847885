import React from "react";
import "./postcontainer.css";
import pd1 from "../../Assets/pitchdeck1.jpg"
import pd2 from "../../Assets/pitchdeck2.jpg"
import pd3 from "../../Assets/pitchdeck3.jpg"

const PostContainer = () => {
  // const [cursorText, setCursorText] = useState(""); // State to store text for cursor

  // // Function to update cursor text when mouse enters project container
  // const handleMouseEnter = (text) => {
  //   setCursorText(text);
  // };

  // // Function to clear cursor text when mouse leaves project container
  // const handleMouseLeave = () => {
  //   setCursorText("");
  // };

  return (
    <div className="post-container">
      <h2 className="description">Pitch Decks</h2>
      <p className="cursor-text">It's Confidential . Go through the meeting and talk to the counsellor for this.</p>

      <div className="gallery">
      
        <div className="project"
          // onMouseEnter={() => handleMouseEnter("It's Confidential . Go through the meeting and talk to the counsellor for this.")}
          // onMouseLeave={handleMouseLeave}
        >
          <img src={pd1} alt="Project 1" />
          <h3>sample</h3>
          <p>
            <br />
            <strong>Type- seed </strong>
            <br />
            <strong>Raised - $x</strong>
            <br />
            <strong>Industry - Tech</strong>
          </p>
        </div>
        
        <div className="project"
          // onMouseEnter={() => handleMouseEnter("It's Confidential . Go through the meeting and talk to the counsellor for this.")}
          // onMouseLeave={handleMouseLeave}
        >
          <img src={pd2} alt="Project 1" 
            
          />
          <h3>sample</h3>
          <p>
            <br />
            <strong>Type- seed </strong>
            <br />
            <strong>Raised - $x</strong>
            <br />
            <strong>Industry - Tech</strong>
          </p>
        </div>

        <div className="project"
          // onMouseEnter={() => handleMouseEnter("It's Confidential . Go through the meeting and talk to the counsellor for this.")}
          // onMouseLeave={handleMouseLeave}
        >
          <img src={pd3} alt="Project 1" />
          <h3>sample</h3>
          <p>
            <br />
            <strong>Type- seed </strong>
            <br />
            <strong>Raised - $x</strong>
            <br />
            <strong>Industry - Tech</strong>
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default PostContainer;
