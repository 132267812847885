import React from "react";
import "./PartnershipHero.css";

const Partnership = () => {
  return (
    <div className="background-image">
      <div className="hero-Container_partnership">
        <div className="hero-text_partnership">
          <h1>Fundraising Consultants<br/>and presentation makers</h1>
          <p>We deliver unmatched expertise and the industry's highest conversion rates</p>
          <button className="btn" onClick={() => window.location.href = 'https://calendly.com/nilambargiri_unblue/fund-raising-consultation'}>Schedule a Call</button>
        </div>
      </div>
    </div>
  );
};

export default Partnership;
