import React from "react";
import "./Ourservices.css";
import bgpic from "../../Assets/service-new.jpg";
import s1 from "../../Assets/servicen1.png";
import s2 from "../../Assets/servicen2.png";
import s3 from "../../Assets/servicen3n.png";
import s4 from "../../Assets/servicen4.png";
import s5 from "../../Assets/servicen5.png";
import s6 from "../../Assets/servicen6.png";

const Ourservices = () => {
  return (
    <div className="main-container">
      <div className="section1">
        <img className="bigpic" src={bgpic} alt="services" />
      </div>
      <div className="section2">
         <div className="Heading-services">
          <h1>OUR SERVICES</h1>
          <p>
            Striking the Perfect Balance between
            Storytelling, Numbers, and Design
          </p>
          </div>
        <div className="services-container">
          <div className="services-list1">
            <ul>
              <li>
                <img className="smallpic" src={s1} alt="services" />
                <h1>
                  IM / Investment
                  <br />
                  Pitch Decks
                </h1>
                <p>Like a great PowerPoint, only better.</p>
              </li>
              <li>
                <img className="smallpic" src={s2} alt="services" />
                <h1>
                  Financial Modeling
                  <br />
                  and Analyses
                </h1>
                <p>Numbers that explain your startup's potential.</p>
              </li>

              <li>
                <img className="smallpic" src={s3} alt="services" />
                <h1>Investors Connect</h1>
                <p>Secure funds from our wide network of investors.</p>
              </li>
            </ul>
          </div>
          <div className="list2">
            <ul>
              <li>
                <img className="smallpic" src={s4} alt="services" />
                <h1>In-depth Analysis</h1>
                <p>Discussing business plan, goals, vision, mission.</p>
              </li>
              <li>
                <img className="smallpic" src={s5} alt="services" />
                <h1>Advice from the best</h1>
                <p>Dedicated advisors for business growth strategy.</p>
              </li>

              <li>
                <img className="smallpic" src={s6} alt="services" />
                <h1>Pitch Deck</h1>
                <p>Experts prep for investor meeting presentation.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourservices;
