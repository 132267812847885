import React from 'react'
import './Youtube.css'

const shortsData = [

{ 
  index: 1,
  vidurl: 'https://www.youtube.com/embed/QhjHpfC-0NY'
},
{ 
  index: 2,
  vidurl: 'https://www.youtube.com/embed/H97V0VbxZHs'
},
{ 
  index: 3,
  vidurl: 'https://www.youtube.com/embed/FCl3IyhchFA'
}




]

const Youtube = () => {
  return (
    <div className='shorts_container'>
      <div className='shorts_title'>
        <h1>Join Us
in Driving<br/> a
Startup?</h1>
      </div>
      <div className='video-container'>
      {
        shortsData.map((item, index) => (
          <div className='video-item' key={index}>
            <iframe src={item.vidurl}   title='shorts' width="240" height="426"  frameborder="0" allowfullscreen />
            
          </div>
        ))
      }
     
    
      </div>
      <div className='after_video'>
      <h1>Interested ?</h1>
      <a href='https://www.youtube.com/@bizunboxed23'><p> View More...</p></a>

      </div>
    </div>
  )
}

export default Youtube
