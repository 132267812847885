import React from "react";
import "./Unicorn.css";

const Unicorn = () => {
  return (
    <div className="background-image">
      <div className="container-unicorn">
        <div className="logo-unicorn">
          <h1>IRIS</h1>
        </div>
        <div className="text-unicorn">
          <h1 className="textline1">
            Want to be the next UNICORN?
            <br />
            Become a STORYTELLER.
          </h1>
          <br />
          <h3 className="textline2">
            Or hire{" "}
            <span className="t2-highlighted">
              IRI-S
            </span>
          </h3>
          <br />
          <h4 className="textline3">
            We have created pitch decks that have closed billions in <br />{" "}
            investments for leading startups and unicorns. Time is nigh!
          </h4>
        
          <button className="btn" onClick={() => window.location.href = 'https://calendly.com/nilambargiri_unblue/fund-raising-consultation'}>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default Unicorn;
