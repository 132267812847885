import React from "react";
import "./MeetingScript.css";
import faq1 from "../../Assets/faq1.png";
import faq2 from "../../Assets/faq2.png";
import faq3 from "../../Assets/faq3.png";

const Meeting_section = () => {
  return (
    <div className="hero-Container__Meeting">
      <div className="hero-text__Meeting">
        <h1>
          Meeting Script/
          <br />
          Investor FAQs
        </h1>
      </div>

      <div className="meeting_container">
        <div className="container">
          <img className="icon" src={faq1} width="60" alt="faq1" />
          <p>What to say, how to say in an investor meeting</p>
        </div>
        <div className="container">
          <img className="icon" src={faq2} width="60" alt="faq2" />
          <p>Mock pitch to help you prepare for the big day</p>
        </div>
        <div className="container">
          <img className="icon" src={faq3} width="60" alt="faq3" />
          <p>Answers to common investor questions</p>
        </div>
      </div>
    </div>
  );
};

export default Meeting_section;
