import { Route, Routes } from "react-router-dom";
import "./App.css";
import Partnerships from "./Pages/Partnerships";
import Propositions from "./Pages/Propositions";
import Perspective from "./Pages/Perspective";
import People from "./Pages/People";
import  Home  from "./Pages/Home";
import ScrollButton from "./Component/Scrollbutton/scrolltotop";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Partnerships" element={<Partnerships />} />
        <Route path="/Propositions" element={<Propositions />} />
        <Route path="/Perspective" element={<Perspective />} />
        <Route path="/People" element={<People />} />
      </Routes>
      <ScrollButton /> 
    </div>
  );
}

export default App;