import React, { useEffect } from "react";
import "./Footerstyles.css"; // Import the CSS file
import logounblue from "../../Assets/logo2.png";
import { Link } from "react-router-dom";

const FooterPage = () => {
  useEffect(() => {
    const currentPage = window.location.pathname.substring(1); // Get the current page path
    const footerLinks = document.querySelectorAll(".footer-links a"); // Get all footer links

    footerLinks.forEach((link) => {
      if (link.getAttribute("href") === `/${currentPage}`) {
        // Check if the link matches the current page
        link.classList.add("active"); // Apply the "active" class to the matching link
      }
    });
  }, []);
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="text-details">
          <img
            width="200"
            height="80"
            src={logounblue}
            alt="Logo"
          />
          
          <br />
          <h2 className="subheadings">India</h2>
          <p>Virtual Office</p>
          <h2 className="subheadings">Reach us</h2>
          <p>unblueconsultation@gmail.com</p>
        </div>
        <div className="col1">
          <h5 className="title1">Pages</h5>
          <ul className="footer-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/Partnerships">Partnership</Link>
            </li>
            <li>
              <Link to="/Propositions">Propositions</Link>
            </li>
            <li>
              <Link to="/Perspective">Perspective</Link>
            </li>
            <li>
              <Link to="/People">People</Link>
            </li>
          </ul>
        </div>

        <div className="col1">
          <h5 className="title2">Services</h5>
          <ul className="footer-links">
            <li>
              <a href="#!">Pitch deck</a>
            </li>
            <li>
              <a href="#!">Financial modelling</a>
            </li>
            <li>
              <a href="#!">Investor connection</a>
            </li>
            <li>
              <a href="#!">Business valuation</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <div>
          &copy; {new Date().getFullYear()} Copyright:
          <a href="www.unblue.in"> Unblue Consultation </a>{" "}
        </div>
        <div className="sitelinks">
          <ul>
            <li>
              <a href="#!">Terms & Condition</a>
            </li>
            <li>
              <a href="#!">Privacy Policy</a>
            </li>
            <li>
              <a href="#!">Refund Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterPage;
