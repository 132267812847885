import React from "react";
import Navbar from "../Component/Navbar/NavModified";
import Footer from "../Component/Footer/Footer";
import Hero from "../Component/Hero/Hero";
import OurMetrics from "../Component/ourMetrics/ourmetrics";
// import Testimonials from "../Component/Testimonial/Testimonial";
import Unicorn from "../Component/Unicorn/Unicorn";
import Ourservices from "../Component/OurService/Ourservices";
export const Home = () => {
  return (
    <div>
    <Navbar />
    <Hero />
    <OurMetrics/>
    <Ourservices/>
    {/* <Testimonials/> */}
    <Unicorn/>
    <Footer />

    </div>
  );
};

export default Home;