import React from "react";
import "./ourmetrics.css";
import mp1 from "../../Assets/mixasset.png"

const OurMetrics = () => {
  // Define an array of objects containing metric data
  const metricsData = [
    { stat: "75+", heading: "Consulting Provides" },
    { stat: "1.5+", heading: "Years Served" },
    { stat: "82+", heading: "Slides Created" },
    { stat: "42cr", heading: "Investment Raised" }
  ];

  return (
    <div className="ourmetrics-container">
    <div className="upper1">
      <div className="ourmetrics-text">
        <h1>Our Metrics</h1>
        <p>What Makes Us Credible as a <br/>Start-Up Consultant</p>
      </div>
      <div className="ourmetrics-image">
      <img src={mp1} alt="metricspic" />
      </div>
      </div>
    
      <div className="ourmetrics-data">
        {metricsData.map((metric, index) => (
          <div className="Data" key={index}>
            <h1 className="stat">{metric.stat}</h1>
            <ul className="sub-headings">
              <li>{metric.heading}</li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurMetrics;
