import React from 'react'
import './Teampage.css'

import team01 from '../../Assets/team-01.png'
import team02 from '../../Assets/team-02.png'
// import team03 from '../../images/team-03.png'
// import team04 from '../../images/team-04.png'


const teamMembers = [
  
    {
        imgUrl: team01,
        name: 'Nilambar Giri',
        position: 'Fundraising Consultants,$200K in Funding, Pitch deck , Content Creator , Founder of @Unblue Consultation'
    },
    {
        imgUrl: team02,
        name: 'Piyush Raj',
        position: 'Marketing Consultant | Ads Expert | Content Creator | Video Editor | Elevating Brands With Innovative Marketing Strategy | Co-founder & CMO at UNBLUE | Lets Connect & Unlock Your Brands Full Potential'
    }
   

    // {
    //     imgUrl: team03,
    //     name: 'OK Tal Mg Lay',
    //     position: 'Customer Service'
    // },

    // {
    //     imgUrl: team04,
    //     name: 'Ma Ma Saung',
    //     position: 'Project Leader'
    // },
]

const Teamdetails = () => {
    return (
        <section className='our__team'>
            <div className='container'>
                <div className='team__content'>
                    <h6 className='subtitle'>OUR TEAM</h6>
                    <h2>
                    Meet Our Team of Creators and Problem Solvers
                    </h2>
                </div>
                <div className='team__wrapper'>
                    {
                        teamMembers.map((item, index) => (
                            <div className='team__item' key={index}>
                                <div className='team__img'>
                                    <img src={item.imgUrl} alt='' />
                                </div>
                                <div className='team__details'>
                                    <h4>{item.name}</h4>
                                    <p className='description'>{item.position}</p>

                                    {/* <div className='team__member-social'>
                                        <span><i class='ri-linkedin-line'></i></span>
                                        <span><i class='ri-twitter-line'></i></span>
                                        <span><i class='ri-facebook-line'></i></span>
                                    </div> */}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Teamdetails;