import React from "react";
import "./Propositions.css"

const perspectiveHero = () => {
  return (
    <div className="hero-Container__prop1">
      <div className="hero-text__prop1">
        <h1>
        Elevate Your Pitch to the 
          <br />
        Next Level!{" "}
        </h1>
        <p>Professionally crafted pitch decks for Seed, Series A, Series B, Series C rounds, as well as Corporate Profiles and Sales Pitches</p>
        <button className="btn" onClick={() => window.location.href = 'https://calendly.com/nilambargiri_unblue/fund-raising-consultation'}>Schedule a Call</button>
      </div>
      
    </div>
  );
};

export default perspectiveHero;
