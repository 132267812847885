import React from 'react';
import Navbar from '../Component/Navbar/NavModified';
import Footer from '../Component/Footer/Footer';
import Team from '../Component/TeamHero/Team';
import Teammembers from '../Component/TeamHero/TeamMembers';

const people=()=>{
  return(
   <div>
   <Navbar/>
   <Team />
   <Teammembers />
   
   <Footer/>

   </div>
  );
}

export default people;