import React, { useState, useEffect } from "react";
import { MenuItems } from "./MenuItems";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
import "./Navmodified.css";
import logounblue from "../../Assets/logo2.png";

function Navbar() {
  const [clicked, setClicked] = useState(false);
  const location = useLocation();

  const handleClick = () => {
    setClicked(prevClicked => !prevClicked); // Toggle the state
  };

  const handleImageClick = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    const currentPage = location.pathname.substring(1); // Get the current page path
    const navLinks = document.querySelectorAll(".nav-menu a"); // Get all navigation links

    navLinks.forEach((link) => {
      if (link.getAttribute("href") === `/${currentPage}`) {
        // Check if the link matches the current page
        link.classList.add("active"); // Apply the "active" class to the matching link
      } else {
        link.classList.remove("active"); // Remove the "active" class from other links
      }
    });
  }, [location.pathname]);

  return (
    <div className="Headers">
      <img
        className="nav-logo"
        src={logounblue}
        alt="Logo"
        onClick={handleImageClick}
      />
      <nav className="NavbarItems">
        <div className="menu-icons" onClick={handleClick}>
          <FontAwesomeIcon icon={clicked ? faTimes : faBars} />
        </div>
        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => (
            <li key={index}>
              <Link className={item.cName} to={item.url}>
                <i className={item.icon}></i>&nbsp;
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <ul className="socials">
        <li>
          <a href="https://www.linkedin.com/in/nilambar-giri/" className="linkedin">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCxCLSk75k_3senmzV5frRpQ" className="youtube">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
        <button className="btn" onClick={() => window.location.href = 'https://calendly.com/nilambargiri_unblue/fund-raising-consultation'}>Get Started</button>
      </ul>
    </div>
  );
}

export default Navbar;
